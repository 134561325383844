import axios from "axios";

export default {
  async getTecnici() {
    const formData = new FormData();
    formData.append("where[users_active]", '1');
    //formData.append("where[]", 'users_type NOT IN (2)');
    formData.append("orderby", "users_last_name");
    formData.append("orderdir", "ASC");

    const response = await axios.post("rest/v1/search/users", formData, {
      headers: {
        Authorization: process.env.VUE_APP_TOKEN,
      },
    });
    //console.log(response);
    return response;
  },
};
