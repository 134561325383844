import axios from "axios";

export default {

    async getProdottiCommessa(projectId: string) {
        const data = new FormData();
        data.append("where[]", `fw_products_id IN (SELECT fw_products_id FROM rel_project_prod WHERE projects_id = '${projectId}')`);
    
        const response = await axios.post("rest/v1/search/fw_products", data, {
          headers: {
            Authorization: process.env.VUE_APP_TOKEN,
          },
        });
        //console.log(response);
        return response;
    },
    
};
